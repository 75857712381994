.mainNav {
  padding: 1rem 0;
  background-color: black;
  display: flex;
  width: 100%;
}
.mainNav__toggler {
  width: 15vw;
  text-align: center;
  height: 8vh;
}
.mainNav__toggler i {
  color: white;
  font-size: 2.6rem !important;
  line-height: 8vh;
  cursor: pointer;
}
.mainNav__logo {
  width: 22.5vw;
  padding: 1rem;
}
.mainNav__logoCont {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainNav__logoCont .text {
  color: #fff;
  font-weight: 600;
}
.logo__downicon {
  width: 2rem;
  object-fit: contain;
  margin-left: .5rem;
  margin-bottom: .3rem;
}
.mainNav__logoCont .text .main, .mainNav__logoCont .text .price {
  margin: 0;
  font-size: 1.5rem;
}
.mainNav__logoCont .text .price {
  margin-top: -.5rem;
}
.mainNav__logo .logo__cfi {
  width: 50%;
  height: 5vh;
  object-fit: contain;
  margin-right: .5rem;
}
.mainNav__logo .logo__kmc {
  width: 100%;
  height: 5vh;
  object-fit: contain;
}
.mainNav__text {
  position: relative;
  width: 40vw;
}
.mainNav__text p {
  margin: 0;
  text-align: center;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  right: -50%;
  transform: translate(-50%, -50%);
  font-size: 1.4rem;
  font-weight: 500;
}
.navbar-nav li a {
  font-weight: 500;
  font-size: 1.5rem;
  color: #333;
}
@media (min-width: 480px) {
  .mainNav {
    padding: 2rem 0;
  }
  .mainNav__toggler {
    height: 18vh;
  }
  .mainNav__toggler i {
    line-height: 18vh;
  }
  .mainNav__text p {
    font-size: 1.9rem;
  }
  .Nav-phone {
    padding: 1rem 2rem 0.5rem 2rem;
  }
  .mainNav__logoCont .text .main, .mainNav__logoCont .text .price {
    font-size: 2rem;
  }
  .mainNav__logo .logo__cfi {
    width: 44%;
    height: 12vh;
    margin-right: 0;
  }
  .mainNav__logo .logo__kmc {
    height: 12vh;
  }
}
@media (min-width: 768px) {
  .mainNav {
    padding: 1rem 0;
  }
  .mainNav__toggler {
    height: 10vh;
  }
  .mainNav__toggler i {
    line-height: 12vh;
    font-size: 3.5rem !important;
  }
  .mainNav__text p {
    font-size: 2.3rem;
  }
  .mainNav__logo .logo__cfi {
    width: 44%;
    height: 8vh;
    margin-right: .5rem;
  }
  .mainNav__logo .logo__kmc {
    height: 8vh;
  }
  .mainNav__logoCont .text .main, .mainNav__logoCont .text .price {
    font-size: 2.5rem;
  }
  .logo__downicon {
    transform: scale(1.5);
    margin-left: 1.2rem;
  }
}
@media (min-width: 992px) {
  .mainNav__toggler i {
    line-height: 9vh;
    font-size: 3.5rem !important;
  }
  .mainNav__logo .logo__kmc {
    height: 8vh;
  }
  .mainNav__text p {
    font-size: 2.8rem;
  }
  .mainNav__logoCont .text .main, .mainNav__logoCont .text .price {
    font-size: 3rem;
  }
  .mainNav__logoCont .text {
    margin-left: 1rem;
  }
}
@media (min-width: 1200px) {
  .mainNav__toggler {
    width: 10vw;
  }
  .mainNav__text {
    width: 50vw;
  }
  .mainNav__logo {
    width: 20vw;
  }
  .mainNav__text p {
    font-size: 2.5rem;
  }
  .Newtop-desktop {
    padding-top: 1rem;
  }
  .navbar-nav li a {
    font-size: 1.7rem;
  }
  .mainNav__logo .logo__cfi {
    width: 30%;
    margin-right: 0;
  }
  .mainNav__logoCont .text .main, .mainNav__logoCont .text .price {
    font-size: 2.3rem;
  }
  .mainNav__logoCont .text {
    margin-left: 0;
  }
}
@media (min-width: 1920px) {
  .mainNav__toggler i {
    font-size: 4rem !important;
  }
  .mainNav__text p {
    font-size: 3.2rem;
  }
  .mainNav__logo .logo__cfi {
    width: 25%;
  }
  .mainNav__logoCont .text {
    margin-left: 1rem;
  }
}
@media (min-width: 2560px) {
  .mainNav__logoCont .text {
    margin-left: 1rem;
  }
}
