.table-responsive {
  border: none;
}
.table > tbody > tr > td {
  vertical-align: middle;
  padding: 2rem;
  white-space: nowrap;
}
.cryptoTable {
  padding: 1rem;
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .cryptoTable {
    padding: 2rem;
  }
}

/* // Medium devices (tablets, 768px and up) */
/* @media (min-width: 768px) and (max-width: 991.98px) {  } */

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .cryptoTable {
    padding: 3rem;
  }
}
/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .cryptoTable {
    padding: 3rem 10%;
  }
}

.cryptoTable__asset {
  display: flex;
}
.cryptoTable__asset img {
  width: 2.8rem;
  height: 2.8rem;
  object-fit: contain;
  margin-right: 1rem;
}
.cryptoTable__asset .asset {
  margin: auto 0;
}
.striped {
  background-color: #f1f2f6;
}
.center {
  text-align: center;
}
th {
  color: #57606f;
  font-size: 1.8rem;
  padding: 1rem 2rem !important;
  white-space: nowrap;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  th {
    font-size: 2rem;
  }
}
@media (min-width: 1200px) {
  th {
    font-size: 2rem;
  }
}
td {
  color: #747d8c;
  font-weight: 500;
  font-size: 1.6rem;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  td {
    font-size: 1.8rem;
  }
}
@media (min-width: 1200px) {
  td {
    font-size: 1.9rem;
  }
}
tr {
  padding: 2rem !important;
}
.text--green {
  color: green;
}
.text--red {
  color: red;
}

.minus-sign {
    font-size: 1.3rem;
    width: 2rem;
}

