.footerLinkIcon {
    font-size: 2em;
    cursor: pointer;
    transition: all .5s;
    color: white;
    margin-right: 2.3rem;
}

.footerLinkIcon:hover {
    transform: scale(1.2);
}
